import { PageUrl } from "./url";

export enum UserRole {
  Reviewer = "REVIEWER",
  ContentCreator = "CREATOR",
  Admin = "ADMIN",
  Manager = "MANAGER",
  SiteAdmin = "SITE-ADMIN",
}

export const MANAGE_SCREENS = {
  [UserRole.Manager]: [
    { key: PageUrl.Employees, label: "Employees" },
    { key: PageUrl.Tracking, label: "Tracking" },
  ],
  [UserRole.Admin]: [
    { key: PageUrl.AccountUsers, label: "Account Users" },
    { key: PageUrl.Achievements, label: "Achievements" },
    { key: PageUrl.DailyQuizEvents, label: "Daily Quiz Events" },
    { key: PageUrl.Feedback, label: "Feedback" },
    { key: PageUrl.MonthExams, label: "Month Exams" },
    { key: PageUrl.MonthlyMission, label: "Monthly Mission" },
    { key: PageUrl.PreRegister, label: "Pre-Registers" },
    { key: PageUrl.Tracking, label: "Tracking" },
  ],
  [UserRole.SiteAdmin]: [
    { key: PageUrl.AccountUsers, label: "Account Users" },
    { key: PageUrl.Feedback, label: "Feedback" },
    { key: PageUrl.PreRegister, label: "Pre-Registers" },
    { key: PageUrl.Tracking, label: "Tracking" },
  ],
};

export const PORTAL_SCREENS = [
  {
    key: PageUrl.Questions,
    label: "Question",
  },
  {
    key: PageUrl.Lessons,
    label: "Lesson",
  },
  {
    key: PageUrl.Units,
    label: "Unit",
  },
  {
    key: PageUrl.Quizzes,
    label: "Quiz",
  },
  {
    key: PageUrl.Exams,
    label: "Exam",
  },
  {
    key: PageUrl.RoadMap,
    label: "Road map",
  },
  {
    key: PageUrl.RoadMapCategory,
    label: "Road map category",
  },
  {
    key: PageUrl.Voucher,
    label: "Voucher",
  },
  {
    key: PageUrl.Calibration,
    label: "Calibration",
  },
  {
    key: PageUrl.Stories,
    label: "Story",
  },
];
