import { ISelectOption } from "../../interfaces/filter";

const voiceCodeList = [
  { name: "HN - Ngọc Huyên", code: "hn_female_ngochuyen_full_48k-fhg" },
  { name: "HN - Anh Khôi - news", code: "hn_male_phuthang_news65dt_44k-fhg" },
  { name: "HN - Thanh Long - talk", code: "hn_male_thanhlong_talk_48k-fhg" },
  { name: "HN - Mai Phương - vdts", code: "hn_female_maiphuong_vdts_48k-fhg" },
  { name: "HN - Mạnh Dũng - news", code: "hn_male_manhdung_news_48k-fhg" },
  { name: "HN - Anh Khôi - story telling", code: "hn_male_phuthang_stor80dt_48k-fhg" },
  { name: "HN - Ngọc Lan - story telling", code: "hn_female_hermer_stor_48k-fhg" },
  { name: "HN - Nguyệt Dương - story telling", code: "hn_female_lenka_stor_48k-phg" },
  { name: "HN - Mạnh Dũng (nhấn nhá) - news", code: "hn_male_manhdung_news_48k-phg" },
  { name: "HN - Việt Bách - child", code: "hn_male_vietbach_child_22k-vc" },
  { name: "en-US - Maddie", code: "Ivy" },
  { name: "en-US - Lucas - Standard", code: "en-US-Standard-D" },
  { name: "en-US - Olivia - Standard", code: "en-US-Standard-E" },
  { name: "en-US - Lucas - Premium", code: "en-US-Wavenet-D" },
  { name: "en-US - Olivia - Premium", code: "en-US-Wavenet-F" },
];

export const VoiceCodeFormOptions: ISelectOption[] = voiceCodeList.map((voiceCode) => {
  return {
    value: voiceCode.code,
    label: voiceCode.name,
  };
});
