export const ELECTIVE_OPTIONS = [
  { label: "No", value: false },
  { label: "Yes", value: true },
];

export const ACTIVE_STATUS_OPTIONS = [
  { label: "Active", value: 1 },
  { label: "Inactive", value: 0 },
];

export const STATUS_OPTIONS = [
  { label: "Draft", value: 0 },
  { label: "Published", value: 1 },
  { label: "Reviewing", value: 2 },
  { label: "Need edit", value: 3 },
  { label: "Unpublished", value: 4 },
];

export const TYPE_OPTIONS = [
  { label: "Drag&drop", value: 0 },
  { label: "Gapfill", value: 1 },
  { label: "Checkbox", value: 2 },
];

export const GENDER = [
  { label: "Nữ", value: 0 },
  { label: "Nam", value: 1 },
];

export const PAYMENT_METHOD = [
  { label: "Combo", value: 1 },
  { label: "Vnpay", value: 2 },
];

// chưa mua hàng, đã mua hàng, đã hết hạn
export const SALE_STATUS_OPTIONS = [
  { label: "-1. Chưa mua hàng", value: -1 },
  { label: "1. Đã mua hàng", value: 1 },
  { label: "2. Đã hết hạn", value: 2 },
  { label: "3. Đã hủy", value: 3 },
  { label: "4. Đã hoàn tiền", value: 4 },
];

export const FEEDBACK_STATUS_OPTIONS = [
  { label: "Chưa xử lý", value: 0, tagColor: "red" },
  { label: "Đang xử lý", value: 1, tagColor: "orange" },
  { label: "Đã xử lý", value: 2, tagColor: "green" },
];
