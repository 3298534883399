import "./UpdateFeedback.scss";
import { Form, Button, Row, Col, Modal, Card } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormInput } from "../../../components/Form/Input";
import Loading from "../../../components/Loading/Loading";
import { useAuth } from "../../../hooks/useAuth";
import { DEFAULT_TIMEOUT } from "../../../constants/timeout";
import { PageUrl } from "../../../constants/url";
import { UserRole } from "../../../constants/auth";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { editFeedback, getFeedbackById } from "../../../services/FeedbackServices";
import { FormSelect } from "../../../components/Form/Select";
import { FEEDBACK_STATUS_OPTIONS } from "../../../constants/filter";

dayjs.extend(utc);

const UpdateFeedback = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useAuth();

  const [form] = Form.useForm();

  const [feedbackId, setFeedbackId] = useState<string | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const updateFeedback = async () => {
    try {
      form.validateFields().then(async () => {
        if (!feedbackId) {
          Modal.error({
            title: "Error",
            content: "Feedback not found",
          });
        } else {
          const formData = form.getFieldsValue();
          const feedbackInfo: any = {
            note: formData.note,
          };

          if (formData.status) {
            feedbackInfo.status = Number(formData.status);
          }

          const response = await editFeedback(feedbackId, feedbackInfo);
          if (response) {
            Modal.success({
              title: "Success",
              type: "success",
              content: `Updated feedback successfully`,
            });
          }
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    const getAllInformation = async () => {
      setIsLoading(true);
      if (feedbackId) {
        const res = (await getFeedbackById(feedbackId)) as any;
        if (res?.errorCode !== 200) return;
        setTimeout(() => {
          if (res.result) {
            console.log(res.result);
            form.setFieldsValue({
              description: res.result.description,
              status: res.result.status,
              gradeName: res.result?.grade?.name ? res.result.grade.name : "N/A",
              unitName: res.result?.unit?.name ? res.result.unit.name : "N/A",
              questionId: res.result?.questionId ? res.result.questionId : "N/A",
              lessonName: res.result?.lesson?.name ? res.result.lesson.name : "N/A",
              sectionName: res.result?.section?.name ? res.result.section.name : "N/A",
              note: res.result.note,
            });
          }
        });
      }
      setTimeout(() => {
        setIsLoading(false);
      }, DEFAULT_TIMEOUT);
    };
    if (!user?.userId) return;
    if (user?.roles?.includes(UserRole.SiteAdmin)) {
      getAllInformation();
    } else {
      navigate(PageUrl.Default);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackId]);

  useEffect(() => {
    if (params.id) {
      setFeedbackId(params.id);
    }
  }, [navigate, params.id, user?.roles]);

  return isLoading ? (
    <Loading />
  ) : (
    <div className="update-feedback-container">
      <Form form={form} className="pic-form">
        <Card title="Feedback information">
          <Row>
            <Col xs={8} md={8} xl={8}>
              <FormInput label="Description" name="description" readonly />
            </Col>
            <Col xs={4} md={4} xl={4}>
              <FormSelect label="Status" name="status" optionData={FEEDBACK_STATUS_OPTIONS} />
            </Col>
          </Row>
          <Row>
            <Col xs={8} md={8} xl={4}>
              <FormInput label="Grade" name="gradeName" readonly />
            </Col>
            <Col xs={8} md={8} xl={4}>
              <FormInput label="Unit" name="unitName" readonly />
            </Col>
            <Col xs={8} md={8} xl={4}>
              <FormInput label="Question" name="questionId" readonly />
            </Col>
            <Col xs={8} md={8} xl={4}>
              <FormInput label="Lesson" name="lessonName" readonly />
            </Col>
            <Col xs={8} md={8} xl={4}>
              <FormInput label="Section" name="sectionName" readonly />
            </Col>
          </Row>
          <Row>
            <Col xs={8} md={8} xl={8}>
              <FormInput label="Admin note" name="note" />
            </Col>
          </Row>
        </Card>

        <div className="form-controller">
          <Button type="primary" style={{ backgroundColor: "#2abb2a" }} onClick={updateFeedback}>
            Update
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default UpdateFeedback;
