import { Layout } from "antd";
import { Header, Content } from "antd/es/layout/layout";
import { Route, Routes } from "react-router-dom";
import Navbar from "./components/NavBar/NavBar";
import UpdateQuestion from "./components/UpdateQuestion/UpdateQuestion";
import QuestionPortal from "./pages/QuestionPortal";
import LessonPortal from "./pages/LessonPortal";
import LoginForm from "./pages/Login";
import UpdateLesson from "./pages/LessonPortal/UpdateLesson/UpdateLesson";
import UnitPortal from "./pages/UnitPortal";
import UpdateUnit from "./pages/UnitPortal/UpdateUnit/UpdateUnit";
import { PageUrl } from "./constants/url";
import QuizPortal from "./pages/QuizPortal";
import UpdateQuiz from "./pages/QuizPortal/UpdateQuiz/UpdateQuiz";
import ExamPortal from "./pages/ExamPortal";
import UpdateExam from "./pages/ExamPortal/UpdateExam/UpdateExam";
import EmployeePortal from "./pages/EmployeePortal";
import UpdateEmployee from "./pages/EmployeePortal/UpdateEmployee/UpdateEmployee";
import AccountAdminPortal from "./pages/AccountAdminPortal";
import UpdateAccountAdmin from "./pages/AccountAdminPortal/UpdateAccountAdmin/UpdateAccountAdmin";
import AccountUserPortal from "./pages/AccountUserPortal";
import UpdateAccountUser from "./pages/AccountUserPortal/UpdateAccountUser/UpdateAccountUser";
import MonthExamPortal from "./pages/MonthExamPortal";
import AchievementPortal from "./pages/AchievementPortal";
import UpdateAchievement from "./pages/AchievementPortal/UpdateAchievement/UpdateAchievement";
import DailyQuizEventPortal from "./pages/DailyQuizEventPortal";
import UpdateDailyQuizEvent from "./pages/DailyQuizEventPortal/UpdateDailyQuizEvent/UpdateDailyQuizEvent";
import Tracking from "./pages/Tracking";
import RoadMapPortal from "./pages/RoadMapPortal";
import UpdateRoadMap from "./pages/RoadMapPortal/UpdateRoadMap/UpdateRoadMap";
import EditExamBranches from "./pages/RoadMapPortal/UpdateRoadMap/EditExamBranches";
import VoucherPortal from "./pages/VoucherPortal";
import GenerateVouchers from "./pages/VoucherPortal/GenerateVouchers";
import RoadMapCategoryPortal from "./pages/RoadMapCategoryPortal";
import UpdateRoadMapCategory from "./pages/RoadMapCategoryPortal/UpdateCategory/UpdateCategory";
import CalibrationPortal from "./pages/CalibrationPortal";
import UpdateCalibration from "./pages/CalibrationPortal/UpdateCalibration/UpdateCalibration";
import StoryPortal from "./pages/StoryPortal";
import UpdateStory from "./pages/StoryPortal/UpdateStory/UpdateStory";
import UpdateMobileSections from "./pages/LessonPortal/UpdateMobileSections/UpdateMobileSections";
import MonthlyMissionPortal from "./pages/DailyMissionPortal";
import UpdateMonthlyMission from "./pages/DailyMissionPortal/UpdateMonthlyMission/UpdateMonthlyMission";
import UpdateQuestionMobileVersion from "./components/UpdateQuestionMobileVersion/UpdateQuestionMobileVersion";
import ShowAccountActivities from "./pages/AccountUserPortal/ShowAccountActivities/ShowAccountActivities";
import PreRegisterPortal from "./pages/PreRegisterPortal";
import UpdatePreRegister from "./pages/PreRegisterPortal/UpdatePreRegister/UpdatePreRegister";
import FeedbackPortal from "./pages/FeedbackPortal";
import UpdateFeedback from "./pages/FeedbackPortal/UpdatePreRegister/UpdateFeedback";

function App() {
  return (
    <div className="App">
      <Layout style={{ minHeight: "100%" }}>
        <Header
          style={{
            display: "inline-flex",
            top: 0,
            zIndex: 1000,
            position: "sticky",
            justifyContent: "space-between",
            padding: "0 50px",
          }}
        >
          <Navbar />
        </Header>
        <Content style={{ margin: "25px 50px", height: "100%" }}>
          <Routes>
            <Route path={PageUrl.Default} element={<QuestionPortal />} />
            <Route path={PageUrl.Signin} element={<LoginForm />} />
            <Route path={PageUrl.Questions} element={<QuestionPortal />} />
            <Route path={`${PageUrl.Questions}${PageUrl.Create}`} element={<UpdateQuestion />} />
            <Route path={`${PageUrl.Questions}${PageUrl.Update}`} element={<UpdateQuestion />} />
            <Route
              path={`${PageUrl.Questions}${PageUrl.UpdateQuestionMobileVersion}`}
              element={<UpdateQuestionMobileVersion />}
            />
            <Route path={PageUrl.Lessons} element={<LessonPortal />} />
            <Route path={`${PageUrl.Lessons}${PageUrl.Create}`} element={<UpdateLesson />} />
            <Route path={`${PageUrl.Lessons}${PageUrl.Update}`} element={<UpdateLesson />} />
            <Route path={`${PageUrl.Lessons}${PageUrl.UpdateMobileSections}`} element={<UpdateMobileSections />} />
            <Route path={PageUrl.Units} element={<UnitPortal />} />
            <Route path={`${PageUrl.Units}${PageUrl.Create}`} element={<UpdateUnit />} />
            <Route path={`${PageUrl.Units}${PageUrl.Update}`} element={<UpdateUnit />} />
            <Route path={PageUrl.Quizzes} element={<QuizPortal />} />
            <Route path={`${PageUrl.Quizzes}${PageUrl.Create}`} element={<UpdateQuiz />} />
            <Route path={`${PageUrl.Quizzes}${PageUrl.Update}`} element={<UpdateQuiz />} />
            <Route path={PageUrl.Exams} element={<ExamPortal />} />
            <Route path={`${PageUrl.Exams}${PageUrl.Create}`} element={<UpdateExam />} />
            <Route path={`${PageUrl.Exams}${PageUrl.Update}`} element={<UpdateExam />} />
            <Route path={PageUrl.Stories} element={<StoryPortal />} />
            <Route path={`${PageUrl.Stories}${PageUrl.Create}`} element={<UpdateStory />} />
            <Route path={`${PageUrl.Stories}${PageUrl.Update}`} element={<UpdateStory />} />
            <Route path={PageUrl.Employees} element={<EmployeePortal />} />
            <Route path={`${PageUrl.Employees}${PageUrl.Create}`} element={<UpdateEmployee />} />
            <Route path={`${PageUrl.Employees}${PageUrl.Update}`} element={<UpdateEmployee />} />
            <Route path={PageUrl.AccountAdmins} element={<AccountAdminPortal />} />
            <Route path={`${PageUrl.AccountAdmins}${PageUrl.Create}`} element={<UpdateAccountAdmin />} />
            <Route path={`${PageUrl.AccountAdmins}${PageUrl.Update}`} element={<UpdateAccountAdmin />} />
            <Route path={PageUrl.AccountUsers} element={<AccountUserPortal />} />
            <Route path={`${PageUrl.AccountUsers}${PageUrl.Create}`} element={<UpdateAccountUser />} />
            <Route path={`${PageUrl.AccountUsers}${PageUrl.Update}`} element={<UpdateAccountUser />} />
            <Route path={`${PageUrl.AccountUsers}${PageUrl.AccountActivities}`} element={<ShowAccountActivities />} />
            <Route path={PageUrl.MonthExams} element={<MonthExamPortal />} />
            <Route path={PageUrl.Achievements} element={<AchievementPortal />} />
            <Route path={`${PageUrl.Achievements}${PageUrl.Create}`} element={<UpdateAchievement />} />
            <Route path={`${PageUrl.Achievements}${PageUrl.Update}`} element={<UpdateAchievement />} />
            <Route path={PageUrl.DailyQuizEvents} element={<DailyQuizEventPortal />} />
            <Route path={`${PageUrl.DailyQuizEvents}${PageUrl.Create}`} element={<UpdateDailyQuizEvent />} />
            <Route path={`${PageUrl.DailyQuizEvents}${PageUrl.Update}`} element={<UpdateDailyQuizEvent />} />
            <Route path={PageUrl.Tracking} element={<Tracking />} />
            <Route path={PageUrl.RoadMap} element={<RoadMapPortal />} />
            <Route path={`${PageUrl.RoadMap}${PageUrl.Create}`} element={<UpdateRoadMap />} />
            <Route path={`${PageUrl.RoadMap}${PageUrl.Update}`} element={<UpdateRoadMap />} />
            <Route path={`${PageUrl.RoadMap}${PageUrl.EditExamBranches}`} element={<EditExamBranches />} />
            <Route path={PageUrl.RoadMapCategory} element={<RoadMapCategoryPortal />} />
            <Route path={`${PageUrl.RoadMapCategory}${PageUrl.Create}`} element={<UpdateRoadMapCategory />} />
            <Route path={`${PageUrl.RoadMapCategory}${PageUrl.Update}`} element={<UpdateRoadMapCategory />} />
            <Route path={PageUrl.Voucher} element={<VoucherPortal />} />
            <Route path={`${PageUrl.Voucher}${PageUrl.Create}`} element={<GenerateVouchers />} />
            <Route path={PageUrl.Calibration} element={<CalibrationPortal />} />
            <Route path={`${PageUrl.Calibration}${PageUrl.Create}`} element={<UpdateCalibration />} />
            <Route path={`${PageUrl.Calibration}${PageUrl.Update}`} element={<UpdateCalibration />} />
            <Route path={PageUrl.MonthlyMission} element={<MonthlyMissionPortal />} />
            <Route path={`${PageUrl.MonthlyMission}${PageUrl.Create}`} element={<UpdateMonthlyMission />} />
            <Route path={`${PageUrl.MonthlyMission}${PageUrl.Update}`} element={<UpdateMonthlyMission />} />
            <Route path={PageUrl.PreRegister} element={<PreRegisterPortal />} />
            <Route path={`${PageUrl.PreRegister}${PageUrl.Create}`} element={<UpdatePreRegister />} />
            <Route path={`${PageUrl.PreRegister}${PageUrl.Update}`} element={<UpdatePreRegister />} />
            <Route path={PageUrl.Feedback} element={<FeedbackPortal />} />
            <Route path={`${PageUrl.Feedback}${PageUrl.Update}`} element={<UpdateFeedback />} />
          </Routes>
        </Content>
      </Layout>
    </div>
  );
}

export default App;
