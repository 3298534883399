import "./UpdateAccountUser.scss";
import { CheckOutlined, CloseCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { Form, Button, Row, Col, Modal, DatePicker, Card, Switch, Checkbox } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormInput } from "../../../components/Form/Input";
import Loading from "../../../components/Loading/Loading";
import { useAuth } from "../../../hooks/useAuth";
import { DEFAULT_TIMEOUT } from "../../../constants/timeout";
import { PageUrl } from "../../../constants/url";
import { UserRole } from "../../../constants/auth";
import {
  addAccountUser,
  changeUserEmailValidatedStatus,
  changeUserPremiumTime,
  editAccountUser,
  getAccountUserById,
  updateUserPic,
} from "../../../services/AccountUserServices";
import { IAccountUser } from "../../../interfaces/IAccountUser";
import { convertToSelectOption, datePickerToDateFormat } from "../../../utils/utils";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Input from "antd/es/input/Input";
import { FormSelect } from "../../../components/Form/Select";
import { getGrades } from "../../../services/QuestionServices";
import { getListSiteAdmins } from "../../../services/AccountAdminServices";
import { getSaleCategories } from "../../../services/CategoryService";

dayjs.extend(utc);

const UpdateAccountUser = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useAuth();

  const [form] = Form.useForm();
  const [picForm] = Form.useForm();

  const accountUserId = useRef<string>(params.id || "");
  const [emailValidated, setEmailValidated] = useState<boolean>(false);
  const [gradeList, setGradeList] = useState([]);
  const [siteAdminList, setSiteAdminList] = useState([]);
  const [saleCategories, setSaleCategories] = useState([]);
  const [premiumTime, setPremiumTime] = useState<string[]>([]);
  const [isDirectTransfer, setIsDirectTransfer] = useState<boolean>(false);

  const [isCreateAccount, setIsCreateAccount] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async () => {
    try {
      form.validateFields().then(async () => {
        const accountUser = form.getFieldsValue() as IAccountUser;
        if (accountUser.birthday) accountUser.birthday = datePickerToDateFormat(accountUser.birthday);
        delete accountUser.elo;
        let response: any;
        if (accountUserId.current) {
          response = await editAccountUser(accountUserId.current, accountUser);
        } else {
          response = await addAccountUser(accountUser);
        }
        Modal.success({
          title: "Success",
          type: "success",
          content: `${accountUserId.current ? "Updated" : "Added"} successfully`,
        });
        if (!accountUserId.current) {
          setTimeout(() => {
            navigate(PageUrl.AccountUsers);
          }, DEFAULT_TIMEOUT);
        }
        accountUserId.current = response.result ? response.result : accountUserId.current;
      });
    } catch (error) {}
  };

  const handleSavePic = async () => {
    try {
      picForm.validateFields().then(async () => {
        const picSetting = picForm.getFieldsValue();
        picSetting.preRegisterId = picSetting.preRegisterId || null;
        picSetting.note = picSetting.note || null;
        const response = await updateUserPic(accountUserId.current, picSetting);
        if (response) {
          Modal.success({
            title: "Success",
            type: "success",
            content: `Updated PIC successfully`,
          });
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    const getAllInformation = async () => {
      const [gradeRes, siteAdminRes, saleCategoryRes] = await Promise.all([
        getGrades(),
        getListSiteAdmins(),
        getSaleCategories(),
      ]);
      saleCategoryRes &&
        setSaleCategories((saleCategoryRes as any).result?.map((item: any) => ({ label: item.name, value: item._id })));
      gradeRes && setGradeList((gradeRes as any).result);
      siteAdminRes &&
        setSiteAdminList((siteAdminRes as any).result?.map((item: any) => ({ label: item.userName, value: item._id })));
      setIsLoading(true);
      if (accountUserId.current) {
        const res = (await getAccountUserById(accountUserId.current)) as any;
        if (res?.errorCode !== 200) return;
        setTimeout(() => {
          form.setFields([
            { name: "fullName", value: res.result.fullName },
            { name: "email", value: res.result.email },
            { name: "phone", value: res.result.phone },
            { name: "studentType", value: res.result.studentType },
            { name: "birthday", value: res.result.birthday ? dayjs(res.result.birthday, "DD/MM/YYYY") : undefined },
            { name: "fatherName", value: res.result.fatherName },
            { name: "fatherEmail", value: res.result.fatherEmail },
            { name: "fatherPhone", value: res.result.fatherPhone },
            { name: "motherName", value: res.result.motherName },
            { name: "motherEmail", value: res.result.motherEmail },
            { name: "motherPhone", value: res.result.motherPhone },
            { name: "gradeId", value: res.result.gradeId },
            { name: "elo", value: res.result.elo },
          ]);
          setEmailValidated(res.result.emailValidated);
          setPremiumTime([
            datePickerToDateFormat(res.result.premiumFrom),
            datePickerToDateFormat(res.result.premiumTo),
          ]);

          if (res.result.saleInfo) {
            picForm.setFields([
              { name: "saleCategoryId", value: res.result.saleInfo?.category?.id },
              { name: "preRegisterId", value: res.result.saleInfo?.preRegisterId },
              { name: "note", value: res.result.saleInfo?.note },
            ]);

            if (res.result.saleInfo.pics) {
              picForm.setFields([{ name: "picIds", value: res.result.saleInfo.pics?.map((item: any) => item.id) }]);
            }
          }
        });
      }
      setTimeout(() => {
        setIsLoading(false);
      }, DEFAULT_TIMEOUT);
    };
    if (!user?.userId) return;
    if (user?.roles?.includes(UserRole.SiteAdmin)) {
      getAllInformation();
    } else {
      navigate(PageUrl.Default);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountUserId.current, user]);

  useEffect(() => {
    if (params.id) {
      accountUserId.current = params.id;
    } else {
      setIsCreateAccount(true);
      if (!user?.roles?.includes(UserRole.SiteAdmin) && !user?.roles?.includes(UserRole.Admin)) {
        navigate(PageUrl.AccountUsers);
      }
    }
  }, [navigate, params.id, user?.roles]);

  return isLoading ? (
    <Loading />
  ) : (
    <div className="update-account-user-container">
      <Card title={isCreateAccount ? "Create account" : "Account setting"}>
        <Form form={form}>
          <div className="form-input">
            <Row>
              <Col xs={8} md={8} xl={6}>
                <FormInput
                  label="Full name"
                  name="fullName"
                  rules={[{ required: true, message: "Field is required" }]}
                />
              </Col>
              <Col xs={8} md={8} xl={6}>
                <FormInput label="Email" name="email" rules={[{ type: "email", message: "Invalid email" }]} />
              </Col>
              <Col xs={8} md={8} xl={6}>
                <Form.Item
                  label="Phone"
                  name="phone"
                  rules={
                    isCreateAccount
                      ? [
                          { required: true, message: "Field is required" },
                          { pattern: /^\d{10}$/, message: "Wrong phone number" },
                        ]
                      : []
                  }
                >
                  <Input disabled={!isCreateAccount} placeholder={isCreateAccount ? "Enter a value" : ""} />
                </Form.Item>
              </Col>
              <Col xs={8} md={8} xl={6}>
                <FormSelect
                  label="Student type"
                  name="studentType"
                  optionData={[
                    { label: "Regular", value: 1 },
                    { label: "IKMC", value: 2 },
                    { label: "TestPrep", value: 3 },
                  ]}
                />
              </Col>
            </Row>
            <Row>
              {isCreateAccount && (
                <Col xs={8} md={8} xl={6}>
                  <FormInput
                    type="password"
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: "Field is required" }]}
                  />
                </Col>
              )}
              <Col xs={8} md={8} xl={6}>
                <Form.Item label="Birthday" name="birthday" rules={[{ required: true, message: "Field is required" }]}>
                  <DatePicker format="DD/MM/YYYY" />
                </Form.Item>
              </Col>
              <Col xs={8} md={8} xl={6}>
                <FormSelect
                  label="Grade"
                  name="gradeId"
                  optionData={convertToSelectOption(gradeList, "_id", "name")}
                  disabled={!isCreateAccount}
                />
              </Col>
              <Col xs={8} md={8} xl={6}>
                <FormInput label="Elo" name="elo" type="number" disabled />
              </Col>
            </Row>
            <Row>
              <Col xs={8} md={8} xl={6}>
                <FormInput label="Father name" name="fatherName" />
              </Col>
              <Col xs={8} md={8} xl={6}>
                <FormInput label="Father email" name="fatherEmail" rules={[{ type: "email" }]} />
              </Col>
              <Col xs={8} md={8} xl={6}>
                <FormInput
                  label="Father phone"
                  name="fatherPhone"
                  rules={[
                    { len: 10, message: "Wrong phone number" },
                    { pattern: /^\d{10}$/, message: "Wrong phone number" },
                  ]}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={8} md={8} xl={6}>
                <FormInput label="Mother name" name="motherName" />
              </Col>
              <Col xs={8} md={8} xl={6}>
                <FormInput label="Mother email" name="motherEmail" rules={[{ type: "email" }]} />
              </Col>
              <Col xs={8} md={8} xl={6}>
                <FormInput
                  label="Mother phone"
                  name="motherPhone"
                  rules={[
                    { len: 10, message: "Wrong phone number" },
                    { pattern: /^\d{10}$/, message: "Wrong phone number" },
                  ]}
                />
              </Col>
            </Row>
          </div>
          <div className="form-controller">
            <Button type="primary" style={{ backgroundColor: "#2abb2a" }} onClick={handleSave}>
              Save
            </Button>
            <Button type="primary" danger icon={<CloseCircleOutlined />} onClick={() => navigate(PageUrl.AccountUsers)}>
              Cancel
            </Button>
          </div>
        </Form>
      </Card>

      {!isCreateAccount && (
        <>
          <Card title="Email setting">
            Change email validation : &nbsp;
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={emailValidated}
              onChange={async (value) => {
                const res = (await changeUserEmailValidatedStatus(accountUserId.current, value)) as any;
                if (res?.errorCode === 200) {
                  setEmailValidated(value);
                  Modal.success({
                    title: "Success",
                    type: "success",
                    content: `Updated successfully`,
                  });
                }
              }}
            />
          </Card>

          <Card title="Premium setting">
            Change premium time : &nbsp;
            <DatePicker.RangePicker
              allowClear
              defaultValue={
                !premiumTime[0] && !premiumTime[1]
                  ? null
                  : [dayjs(premiumTime[0], "DD/MM/YYYY"), dayjs(premiumTime[1], "DD/MM/YYYY")]
              }
              format={["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"]}
              onChange={(_, date) => setPremiumTime(date)}
            />
            <Checkbox style={{ margin: "0 16px" }} onChange={(e) => setIsDirectTransfer(e.target.checked)}>
              Is direct transfer
            </Checkbox>
            <Button
              type="primary"
              style={{ backgroundColor: "#2abb2a" }}
              onClick={async () => {
                const res = (await changeUserPremiumTime(
                  accountUserId.current,
                  dayjs(premiumTime[0], "DD/MM/YYYY").utc().format(),
                  dayjs(premiumTime[1], "DD/MM/YYYY").utc().format(),
                  isDirectTransfer
                )) as any;
                if (res?.errorCode === 200) {
                  Modal.success({
                    title: "Success",
                    type: "success",
                    content: `Updated successfully`,
                  });
                }
              }}
            >
              Save
            </Button>
          </Card>

          <Card title="PICs setting">
            <Form form={picForm} className="pic-form">
              <Row>
                <Col xs={6} md={6} xl={6}>
                  <FormSelect
                    mode="multiple"
                    label="PICs"
                    name="picIds"
                    optionData={siteAdminList}
                    rules={[{ required: true, message: "Pics required" }]}
                  />
                </Col>
                <Col xs={6} md={6} xl={6}>
                  <FormSelect
                    label="Sale category"
                    name="saleCategoryId"
                    optionData={saleCategories}
                    rules={[{ required: true, message: "Sale category required" }]}
                  />
                </Col>
                <Col xs={6} md={6} xl={6}>
                  <FormInput label="Pre-register id" name="preRegisterId" />
                </Col>
                <Col xs={6} md={6} xl={6}>
                  <FormInput label="Note" name="note" />
                </Col>
              </Row>
              <div className="form-controller">
                <Button type="primary" style={{ backgroundColor: "#2abb2a" }} onClick={handleSavePic}>
                  Save
                </Button>
              </div>
            </Form>
          </Card>
        </>
      )}
    </div>
  );
};

export default UpdateAccountUser;
