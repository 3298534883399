import "./index.scss";
import { useEffect, useRef, useState } from "react";
import Loading from "../../components/Loading/Loading";
import { Button, Col, DatePicker, Form, message, Row, Space, Table, Tag, Tooltip } from "antd";
import { EditOutlined, SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { IFeedbackFilter } from "../../interfaces/filter";
import { FormInput } from "../../components/Form/Input";
import { UserRole } from "../../constants/auth";
import { DEFAULT_TIMEOUT } from "../../constants/timeout";
import { useAuth } from "../../hooks/useAuth";
import { PageUrl } from "../../constants/url";
import { FEEDBACK_STATUS_OPTIONS } from "../../constants/filter";
import { FormSelect } from "../../components/Form/Select";
import dayjs from "dayjs";
import { customSessionStorage } from "../../utils/storage";
import { getFeedbacks } from "../../services/FeedbackServices";

const FeedbackPortal = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const tableRef = useRef<any>();
  const [tableData, setTableData] = useState<any>([]);
  const tableTotal = useRef<number>();
  const filterData = useRef<IFeedbackFilter>({ page: 1, size: 10 });

  const handleFilter = () => {
    const formValues = form.getFieldsValue();

    const filter: IFeedbackFilter = {
      filter: formValues.filter,
      status: formValues.status,
    };

    if (formValues.from) {
      filter.from = dayjs(formValues.from).startOf("day").toISOString();
    }

    if (formValues.to) {
      filter.to = dayjs(formValues.to).endOf("day").toISOString();
    }

    filterData.current = { ...filter, page: 1, size: 10 };
    customSessionStorage("filterFeedback", filterData.current);
    fetchFeedbacks();
  };

  const fetchFeedbacks = async () => {
    const response = await getFeedbacks(filterData.current);
    if (response) {
      setTableData((response as any).result.records || []);
      tableTotal.current = (response as any).result.recordCount;
    }
  };

  const handleChangePage = async (page: number) => {
    filterData.current.page = page;
    await fetchFeedbacks();
  };

  const handleTableChange = async (pagination: TablePaginationConfig, _: any, sorter: any) => {
    filterData.current.page = pagination.current;
    filterData.current.size = pagination.pageSize;
    await fetchFeedbacks();
  };

  const handleEditFeedback = async (id: string) => {
    navigate(`${PageUrl.Feedback}${PageUrl.Update}`.replace(":id", id));
  };

  const getColumn = (): ColumnsType<any> => {
    return [
      {
        title: "Order",
        key: "order",
        align: "center",
        width: 50,
        render: (_, __, index) => {
          if (filterData.current.page && filterData.current.size) {
            return (filterData.current.page - 1) * filterData.current.size + index + 1;
          }
          return index + 1;
        },
      },
      {
        title: "User ID",
        dataIndex: ["user", "id"],
        key: "id",
        align: "center",
        width: 100,
        render: (e) => (
          <Tooltip title={e}>
            <span
              className="none-select"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigator.clipboard.writeText(e);
                messageApi.open({
                  type: "success",
                  content: "Copied to clipboard!",
                  duration: 1,
                });
              }}
            >
              ID
            </span>
          </Tooltip>
        ),
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        align: "center",
        render: (value) => value || "",
      },
      {
        title: "Grade",
        dataIndex: ["grade", "name"],
        key: "grade",
        align: "center",
        width: 100,
        render: (value) => value || "",
      },
      {
        title: "Unit",
        dataIndex: ["unit", "name"],
        key: "unit",
        align: "center",
        render: (value) => value || "",
      },
      {
        title: "Lesson",
        dataIndex: ["lesson", "name"],
        key: "lesson",
        align: "center",
        render: (value) => value || "",
      },
      {
        title: "Section",
        dataIndex: ["section", "name"],
        key: "section",
        align: "center",
        render: (value) => value || "",
      },
      {
        title: "Question",
        dataIndex: "questionId",
        key: "questionId",
        align: "center",
        width: 100,
        render: (e) => {
          if (!e) return "";
          return (
            <Tooltip title={e}>
              <span
                className="none-select"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigator.clipboard.writeText(e);
                  messageApi.open({
                    type: "success",
                    content: "Copied to clipboard!",
                    duration: 1,
                  });
                }}
              >
                ID
              </span>
            </Tooltip>
          );
        },
      },

      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        align: "center",
        width: 100,
        render: (value) => {
          const status = FEEDBACK_STATUS_OPTIONS.find((item) => item.value === value);
          return <Tag color={status?.tagColor}>{status?.label}</Tag>;
        },
      },
      {
        title: "Admin note",
        dataIndex: "note",
        key: "note",
        align: "center",
        width: 150,
        render: (value) => value || "",
      },
      {
        title: "Created at",
        dataIndex: ["recordInfo", "createdAt"],
        key: "createdAt",
        align: "center",
        width: 125,
        render: (value) => {
          if (!value || value === "0001-01-01T00:00:00Z") {
            return "";
          }

          return dayjs(value).format("DD/MM/YYYY");
        },
      },
      {
        title: "Updated at",
        dataIndex: ["recordInfo", "updatedAt"],
        key: "updatedAt",
        align: "center",
        width: 125,
        render: (value) => {
          if (!value || value === "0001-01-01T00:00:00Z") {
            return "";
          }

          return dayjs(value).format("DD/MM/YYYY");
        },
      },
      {
        title: "Action",
        key: "action",
        align: "center",
        width: 100,
        render: (record: any) => (
          <span style={{ display: "inline-flex", gap: "8px" }}>
            {user?.roles?.includes(UserRole.SiteAdmin) && (
              <>
                <Space size="middle">
                  <Tooltip title="Edit feedback">
                    <Button icon={<EditOutlined />} onClick={() => handleEditFeedback(record._id)} />
                  </Tooltip>
                </Space>
              </>
            )}
          </span>
        ),
      },
    ] as ColumnsType<any>;
  };

  useEffect(() => {
    const getAllInformation = async () => {
      setIsLoading(true);
      if (sessionStorage.getItem("filterFeedback")) {
        const sessionFilter = JSON.parse(sessionStorage.getItem("filterFeedback")!);
        // check expires session
        if (sessionFilter?.expiresAt < Date.now()) {
          sessionStorage.removeItem("filterFeedback");
        } else {
          form.setFields([
            { name: "filter", value: sessionFilter.filter },
            { name: "status", value: sessionFilter.status },
            { name: "from", value: sessionFilter.from ? dayjs(sessionFilter.from) : null },
            { name: "to", value: sessionFilter.to ? dayjs(sessionFilter.to) : null },
          ]);

          filterData.current = {
            filter: sessionFilter.filter,
            status: sessionFilter.status,
            from: sessionFilter.from,
            to: sessionFilter.to,
            page: 1,
            size: 10,
          };
        }
      }
      fetchFeedbacks();
      setTimeout(() => {
        setIsLoading(false);
      }, DEFAULT_TIMEOUT);
    };

    if (!user?.userId) return;
    if (user?.roles?.includes(UserRole.SiteAdmin) || user?.roles?.includes(UserRole.Admin)) {
      getAllInformation();
    } else {
      navigate(PageUrl.Default);
    }
  }, [form, navigate, user]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      {contextHolder}
      <div className="feedback-portal-container">
        <div className="filter-container">
          <Form form={form} onFinish={handleFilter}>
            <Row>
              <Col xs={6} md={6} xl={6}>
                <FormInput label="Filter" name="filter" allowClear />
              </Col>
              <Col xs={6} md={6} xl={6}>
                <FormSelect label="Status" name="status" optionData={FEEDBACK_STATUS_OPTIONS} allowClear />
              </Col>
              <Col xs={6} md={6} xl={6}>
                <Form.Item label="Created from" name="from">
                  <DatePicker format="DD/MM/YYYY" />
                </Form.Item>
              </Col>
              <Col xs={6} md={6} xl={6}>
                <Form.Item label="Created to" name="to">
                  <DatePicker format="DD/MM/YYYY" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col className="filter-controller">
                <Button icon={<SearchOutlined />} type="primary" htmlType="submit">
                  Search
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
        <div className="table-container">
          <div style={{ fontSize: "12px", color: "mediumblue" }}>* Nhấp chuột vào từng ID để copy</div>
          <Table
            columns={getColumn()}
            dataSource={tableData || []}
            pagination={{ total: tableTotal.current, showSizeChanger: false, onChange: handleChangePage }}
            onChange={handleTableChange}
            ref={tableRef}
            rowKey={(record) => record._id}
          />
        </div>
      </div>
    </>
  );
};

export default FeedbackPortal;
