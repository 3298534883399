import { IFeedbackFilter } from "../interfaces/filter";
import instance, { apiConfig } from "./instance";

const feedbackUrl = "/tracking/v1/admin/feedback";

export const getFeedbacks = (filter: IFeedbackFilter) => {
  return instance.get(`${feedbackUrl}/get-list-feedbacks`, apiConfig(filter));
};

export const getFeedbackById = (id: string) => {
  return instance.get(`${feedbackUrl}/get-a-feedback/${id}`, apiConfig());
};

export const editFeedback = (id: string, data: any): Promise<any> => {
  return instance.post(`${feedbackUrl}/edit-a-feedback/${id}`, data, apiConfig());
};
